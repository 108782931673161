import Layout from "@/components/Layout";
import MobileLayout from "@/components/Layout/MobileLayout";
import Loader from "@/components/ui/Loader";
import { useMyCampaigns } from "@/services/campaign";
import NewDashboard from "@/components/NewCampaignViews/NewDashboard";
import NewMobileDashboard from "@/components/NewCampaignViews/Mobile/MobileHome";
import useMobile from "@/lib/useMobile";

export default function Home() {
  const { data } = useMyCampaigns();
  const { isMobile } = useMobile();
  const campaign =
    data?.find((c) => c.song?.spotifyId && c.artist?.image) ||
    data?.find((c) => true);
  if (data?.length === 0)
    return (
      <Layout title="" page="dashboard">
        <div></div>
      </Layout>
    );
  if (!campaign) {
    return (
  <div id="root-portal">
      <Layout title="" page="dashboard">
        <Loader full={true} />
      </Layout>
      </div>
    );
  }
    
  if (isMobile === null) {
    return (<div id="root-portal">
      <Layout title="" page="dashboard">
        <Loader full={true} />
      </Layout>
      </div>)
    }
  else
    return (
      <div id="root-portal">
        {isMobile ? 
        <MobileLayout title="" page="dashboard" wide>
          <NewMobileDashboard id={campaign.id.toString()} setId={() => {}} home/>
        </MobileLayout> :  
        <div>
          <Layout title="" page="dashboard">
            <NewDashboard id={campaign.id.toString()} setId={() => {}} home/>
          </Layout>
        </div>
      }
      </div>
    );
}
